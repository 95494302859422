<template>
  <div>
    <el-table
      :border="!isInPojectImplementation"
      :data="rows"
      @sort-change="doChangeSort"
      ref="table"
      row-key="id"
      v-loading="loading"
    >
      <el-table-column
        type="selection"
        width="55"
        v-if="!isInPojectImplementation"
      ></el-table-column>

      <el-table-column
        :label="fields.fundingAgency.label"
        :prop="fields.fundingAgency.name"
      >
        <template slot-scope="scope">
          <app-list-item-relation-to-one
            :label="fields.fundingAgency.label"
            :readPermission="fields.fundingAgency.readPermission"
            :navigatePermission="fields.fundingAgency.navigatePermission"
            :url="fields.fundingAgency.viewUrl"
            :value="presenter(scope.row, 'fundingAgency')"
          ></app-list-item-relation-to-one>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.contactPerson.label"
        :prop="fields.contactPerson.name"
      >
        <template slot-scope="scope">{{
          presenter(scope.row, 'contactPerson')
        }}</template>
      </el-table-column>

      <el-table-column
        :label="fields.tenderUrl.label"
        :prop="fields.tenderUrl.name"
      >
        <template slot-scope="scope">{{
          presenter(scope.row, 'tenderUrl')
        }}</template>
      </el-table-column>

      <el-table-column
        :label="fields.deadlineForSubmission.label"
        :prop="fields.deadlineForSubmission.name"
        sortable="custom"
      >
        <template slot-scope="scope">{{
          presenter(scope.row, 'deadlineForSubmission')
        }}</template>
      </el-table-column>

      <el-table-column
        :label="fields.availabilityOfGrantorDecision.label"
        :prop="fields.availabilityOfGrantorDecision.name"
        sortable="custom"
      >
        <template slot-scope="scope">{{
          presenter(scope.row, 'availabilityOfGrantorDecision')
        }}</template>
      </el-table-column>

      <el-table-column
        :label="fields.decision.label"
        :prop="fields.decision.name"
        sortable="custom"
      >
        <template slot-scope="scope">
          <el-tag
            :type="
              getDecisionTagType(scope.row[fields.decision.name])
            "
            v-if="scope.row[fields.decision.name]"
            >{{ presenter(scope.row, 'decision') }}</el-tag
          >
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.amount.label"
        :prop="fields.amount.name"
        sortable="custom"
        align="right"
      >
        <template slot-scope="scope">{{
          presenter(scope.row, 'amount')
        }}</template>
      </el-table-column>

      <el-table-column
        :label="fields.decisionFile.label"
        :prop="fields.decisionFile.name"
      >
        <template v-slot="scope">
          <app-list-item-file
             :permissions="fields.decisionFile.permissions"
            :value="presenter(scope.row, 'decisionFile')"
          ></app-list-item-file>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.projectDuration.label"
        :prop="fields.projectDuration.name"
        sortable="custom"
      >
        <template slot-scope="scope">{{
          presenter(scope.row, 'projectDuration')
        }}</template>
      </el-table-column>

      <el-table-column
        :fixed="isMobile ? undefined : 'right'"
        align="center"
        v-if="isInPojectImplementation"
        width="180"
      >
        <template slot-scope="scope">
          <div class="table-actions">
            <el-button
              type="text"
              @click="doOpenModal(scope.row)"
              v-if="hasPermissionToEdit"
            >
              <app-i18n code="common.edit"></app-i18n>
            </el-button>

            <el-button
              :disabled="destroyLoading"
              @click="doDestroyWithConfirm(scope.row.id)"
              type="text"
              v-if="hasPermissionToDestroy"
            >
              <app-i18n code="common.destroy"></app-i18n>
            </el-button>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :fixed="isMobile ? undefined : 'right'"
        align="center"
        width="180"
        v-if="!isInPojectImplementation"
      >
        <template slot-scope="scope">
          <div class="table-actions">
            <router-link :to="`/grant/${scope.row.id}`">
              <el-button type="text">
                <app-i18n code="common.view"></app-i18n>
              </el-button>
            </router-link>

            <router-link
              :to="`/grant/${scope.row.id}/edit`"
              v-if="hasPermissionToEdit"
            >
              <el-button type="text">
                <app-i18n code="common.edit"></app-i18n>
              </el-button>
            </router-link>

            <el-button
              :disabled="destroyLoading"
              @click="doDestroyWithConfirm(scope.row.id)"
              type="text"
              v-if="hasPermissionToDestroy"
            >
              <app-i18n code="common.destroy"></app-i18n>
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="el-pagination-wrapper">
      <el-pagination
        v-if="!isInPojectImplementation"
        :current-page="pagination.currentPage || 1"
        :disabled="loading"
        :layout="paginationLayout"
        :total="count"
        @current-change="doChangePaginationCurrentPage"
        @size-change="doChangePaginationPageSize"
      ></el-pagination>
    </div>
    <portal to="modal">
      <app-grant-form-modal
        :visible="dialogVisible"
        :record="record"
        v-if="dialogVisible"
        @success="onModalSuccess"
        @close="onModalClose"
      ></app-grant-form-modal>
    </portal>
  </div>
</template>

<script>
import { GrantModel } from '@/modules/grant/grant-model';
import { mapGetters, mapActions } from 'vuex';
import { GrantPermissions } from '@/modules/grant/grant-permissions';
import GrantFormModal from '@/modules/grant/components/grant-form-modal';
import { i18n } from '@/i18n';
import { EventBus } from '@/eventBus';

const { fields } = GrantModel;

export default {
  name: 'app-grant-list-table',
  props: ['isInPojectImplementation', 'releaseMode'],

  components: {
    [GrantFormModal.name]: GrantFormModal,
  },

  data() {
    return {
      dialogVisible: false,
      record: null,
    };
  },

  mounted() {
    this.doMountTable(this.$refs.table);
  },

  computed: {
    ...mapGetters({
      rows: 'grant/list/rows',
      count: 'grant/list/count',
      loading: 'grant/list/loading',
      pagination: 'grant/list/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      destroyLoading: 'grant/destroy/loading',
      paginationLayout: 'layout/paginationLayout',
    }),

    hasPermissionToEdit() {
      return new GrantPermissions(this.currentUser).edit;
    },

    hasPermissionToDestroy() {
      return new GrantPermissions(this.currentUser).destroy;
    },

    isReleaseMode() {
      return this.releaseMode === undefined ? false : true;
    },

    inProjectRelease() {
      return this.isInPojectImplementation;
    },

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doChangeSort: 'grant/list/doChangeSort',
      doChangePaginationCurrentPage:
        'grant/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize:
        'grant/list/doChangePaginationPageSize',
      doMountTable: 'grant/list/doMountTable',
      doDestroy: 'grant/destroy/doDestroy',
    }),

    presenter(row, fieldName) {
      return GrantModel.presenter(row, fieldName);
    },

    isDecisionNull(decision) {
      return decision === null ? false : true;
    },

    getDecisionTagType(decision) {
      if (decision === 'positive') {
        return 'success';
      }
      if (decision === 'negative') {
        return 'danger';
      }
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.$confirm(
          i18n('common.areYouSure'),
          i18n('common.confirm'),
          {
            confirmButtonText: i18n('common.yes'),
            cancelButtonText: i18n('common.no'),
            type: 'warning',
          },
        );

        await this.doDestroy(id);
        if (this.isInPojectImplementation) {
          return this.$emit('removeExternalApplication', { id });
        }
      } catch (error) {
        // no
      }
    },

    doOpenModal(payload) {
      this.record = payload;
      this.dialogVisible = true;
    },

    onModalSuccess() {
      this.dialogVisible = false;
      EventBus.$emit('updateGrantsList');
    },

    onModalClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style>
</style>
