<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n
          code="entities.grant.menu"
        ></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n
          code="entities.grant.list.title"
        ></app-i18n>
      </h1>

      <app-grant-list-toolbar></app-grant-list-toolbar>
      <app-grant-list-filter></app-grant-list-filter>
      <app-grant-list-table
        :isInPojectImplementation="false"
      ></app-grant-list-table>
    </div>
  </div>
</template>

<script>
import GrantListFilter from '@/modules/grant/components/grant-list-filter.vue';
import GrantListTable from '@/modules/grant/components/grant-list-table.vue';
import GrantListToolbar from '@/modules/grant/components/grant-list-toolbar.vue';

export default {
  name: 'app-grant-list-page',

  components: {
    [GrantListFilter.name]: GrantListFilter,
    [GrantListTable.name]: GrantListTable,
    [GrantListToolbar.name]: GrantListToolbar,
  },
};
</script>

<style>
</style>
